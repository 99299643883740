.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
 
.home-image {
  width: 200px;
  margin-top: var(--dl-space-space-twounits);
  object-fit: cover;
}
 
.home-text100 {
  margin-top: 0px;
  text-align: center;
  margin-bottom: 0px;
}
 
.home-text104 {
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
 
.home-text107 {
  text-align: center;
}
 
.home-link {
  display: contents;
}
 
.home-component10 {
  text-decoration: none;
}
 
.home-text110 {
  display: inline-block;
}
 
.home-text111 {
  display: inline-block;
}
 
.home-text112 {
  display: inline-block;
}
 
.home-text113 {
  display: inline-block;
}
 
.home-text114 {
  display: inline-block;
}
 
.home-text117 {
  display: inline-block;
}
 
.home-text118 {
  display: inline-block;
}
 
.home-text119 {
  display: inline-block;
}
 
.home-text120 {
  display: inline-block;
}
 
.home-text123 {
  display: inline-block;
}
 
.home-text124 {
  display: inline-block;
}
 
.home-text127 {
  display: inline-block;
}
 
.home-text128 {
  display: inline-block;
}
 
.home-text129 {
  display: inline-block;
}
 
.home-text134 {
  display: inline-block;
}
 
.home-text137 {
  display: inline-block;
}
 
.home-text138 {
  display: inline-block;
}
 
.home-text139 {
  display: inline-block;
}
 
.home-text142 {
  display: inline-block;
}
 
.home-text145 {
  display: inline-block;
}
 
.home-text148 {
  display: inline-block;
}
 
.home-text149 {
  display: inline-block;
}
 
.home-text150 {
  display: inline-block;
}
 
.home-text151 {
  display: inline-block;
}
 
.home-text152 {
  display: inline-block;
}
 
.home-text160 {
  display: inline-block;
}
 
.home-text161 {
  display: inline-block;
}
 
.home-text162 {
  display: inline-block;
}
 
.home-text163 {
  display: inline-block;
}
 
.home-text164 {
  display: inline-block;
}
 
.home-text165 {
  display: inline-block;
}
 
.home-text166 {
  display: inline-block;
}
 
.home-text170 {
  display: inline-block;
}
 
.home-text171 {
  display: inline-block;
}
 
.home-text172 {
  display: inline-block;
}
 
.home-text173 {
  display: inline-block;
}
 
.home-text174 {
  display: inline-block;
}
 
.home-text175 {
  display: inline-block;
}
 
.home-text176 {
  display: inline-block;
}
 
.home-text177 {
  display: inline-block;
}
 
.home-text178 {
  display: inline-block;
}
 
.home-text179 {
  display: inline-block;
}
 
.home-text180 {
  display: inline-block;
}
 
.home-text181 {
  display: inline-block;
}
 
.home-text182 {
  display: inline-block;
}
 
.home-text183 {
  display: inline-block;
}
 
.home-text184 {
  display: inline-block;
}
 
.home-text185 {
  display: inline-block;
}
 
.home-text186 {
  display: inline-block;
}
 
.home-text187 {
  display: inline-block;
}
 
.home-text188 {
  display: inline-block;
}
 
.home-text189 {
  display: inline-block;
}
 
.home-text190 {
  display: inline-block;
}
 
.home-text191 {
  display: inline-block;
}
 
.home-text192 {
  display: inline-block;
}
 
.home-text193 {
  display: inline-block;
}
 
.home-text194 {
  display: inline-block;
}
 
.home-text195 {
  display: inline-block;
}
 
.home-text196 {
  display: inline-block;
}
 
.home-text199 {
  display: inline-block;
}
 
.home-text210 {
  display: inline-block;
}
 
.home-text211 {
  display: inline-block;
}
 
.home-text212 {
  display: inline-block;
}
 
.home-text213 {
  display: inline-block;
}
 
.home-text214 {
  display: inline-block;
}
 
.home-text215 {
  display: inline-block;
}
 
.home-text216 {
  display: inline-block;
}
 
.home-text217 {
  display: inline-block;
}
 
@media(max-width: 1200px) {
  .home-image {
    width: var(--dl-size-size-large);
  }
  .home-text100 {
    margin-top: var(--dl-space-space-unit);
  }
  .home-text104 {
    margin: var(--dl-space-space-oneandhalfunits);
  }
  .home-text107 {
    margin: var(--dl-space-space-oneandhalfunits);
  }
}
 
@media(max-width: 479px) {
  .home-image {
    margin-top: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
}
