.button-container {
  display: flex;
  position: relative;
}
 
.button-text2 {
  display: inline-block;
}
 
.buttonroot-class-name {
  margin-top: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-threeunits);
}
 
@media(max-width: 479px) {
  .button-button {
    width: 100%;
  }
}
