.cta9-container2 {
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
 
.cta9-content {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: auto;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
}
 
.cta9-container3 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.cta9-row {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
}
 
.cta9-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: center;
  justify-content: flex-start;
}
 
.cta9-link {
  text-decoration: none;
}
 
.cta9-text4 {
  display: inline-block;
}
 
.cta9-text5 {
  display: inline-block;
}
 
.cta9-text6 {
  display: inline-block;
}
 
@media(max-width: 1200px) {
  .cta9-container3 {
    align-items: center;
  }
}
 
@media(max-width: 991px) {
  .cta9-container2 {
    gap: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
  }
  .cta9-container3 {
    align-self: flex-start;
    align-items: flex-start;
  }
  .cta9-row {
    width: 100%;
    position: relative;
    flex-direction: row;
    justify-content: flex-start;
  }
  .cta9-container4 {
    align-self: flex-end;
    justify-content: center;
  }
}
 
@media(max-width: 479px) {
  .cta9-row {
    justify-content: center;
  }
  .cta9-container4 {
    width: 100%;
    flex-direction: column;
  }
}
