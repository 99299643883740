.testimonial17-max-width {
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.testimonial17-container10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: 600px;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
}
 
.testimonial17-text11 {
  text-align: center;
}
 
.testimonial17-container12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.testimonial17-image10 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
 
.testimonial17-container13 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.testimonial17-text14 {
  text-align: left;
}
 
.testimonial17-container14 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.testimonial17-image11 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
 
.testimonial17-container15 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.testimonial17-text17 {
  text-align: left;
}
 
.testimonial17-container16 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.testimonial17-image12 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
 
.testimonial17-container17 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.testimonial17-text20 {
  text-align: left;
}
 
.testimonial17-container18 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.testimonial17-image13 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
 
.testimonial17-container19 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.testimonial17-text23 {
  text-align: left;
}
 
.testimonial17-container20 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.testimonial17-image14 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
 
.testimonial17-container21 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.testimonial17-text26 {
  text-align: left;
}
 
.testimonial17-container22 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.testimonial17-image15 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
 
.testimonial17-container23 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.testimonial17-text29 {
  text-align: left;
}
 
.testimonial17-container24 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.testimonial17-image16 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
 
.testimonial17-container25 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.testimonial17-text32 {
  text-align: left;
}
 
.testimonial17-container26 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.testimonial17-image17 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
 
.testimonial17-container27 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.testimonial17-text35 {
  text-align: left;
}
 
.testimonial17-container28 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.testimonial17-image18 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
 
.testimonial17-container29 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.testimonial17-text38 {
  text-align: left;
}
 
.testimonial17-container30 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.testimonial17-image19 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
 
.testimonial17-container31 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.testimonial17-text41 {
  text-align: left;
}
 
.testimonial17-container32 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.testimonial17-image20 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
 
.testimonial17-container33 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.testimonial17-text44 {
  text-align: left;
}
 
.testimonial17-container34 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.testimonial17-image21 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
 
.testimonial17-container35 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.testimonial17-text47 {
  text-align: left;
}
 
.testimonial17-text48 {
  display: inline-block;
}
 
.testimonial17-text49 {
  display: inline-block;
}
 
.testimonial17-text50 {
  display: inline-block;
}
 
.testimonial17-text51 {
  display: inline-block;
}
 
.testimonial17-text52 {
  display: inline-block;
}
 
.testimonial17-text53 {
  display: inline-block;
}
 
.testimonial17-text54 {
  display: inline-block;
}
 
.testimonial17-text55 {
  display: inline-block;
}
 
.testimonial17-text56 {
  display: inline-block;
}
 
.testimonial17-text57 {
  display: inline-block;
}
 
.testimonial17-text58 {
  display: inline-block;
}
 
.testimonial17-text59 {
  display: inline-block;
}
 
.testimonial17-text60 {
  display: inline-block;
}
 
.testimonial17-text61 {
  display: inline-block;
}
 
.testimonial17-text62 {
  display: inline-block;
}
 
.testimonial17-text63 {
  display: inline-block;
}
 
.testimonial17-text64 {
  display: inline-block;
}
 
.testimonial17-text65 {
  display: inline-block;
}
 
.testimonial17-text66 {
  display: inline-block;
}
 
.testimonial17-text67 {
  display: inline-block;
}
 
.testimonial17-text68 {
  display: inline-block;
}
 
.testimonial17-text69 {
  display: inline-block;
}
 
.testimonial17-text70 {
  display: inline-block;
}
 
.testimonial17-text71 {
  display: inline-block;
}
 
.testimonial17-text72 {
  display: inline-block;
}
 
.testimonial17-text73 {
  display: inline-block;
}
 
.testimonial17-text74 {
  display: inline-block;
}
 
.testimonial17-text75 {
  display: inline-block;
}
 
.testimonial17-text76 {
  display: inline-block;
}
 
.testimonial17-text77 {
  display: inline-block;
}
 
.testimonial17-text78 {
  display: inline-block;
}
 
.testimonial17-text79 {
  display: inline-block;
}
 
.testimonial17-text80 {
  display: inline-block;
}
 
.testimonial17-text81 {
  display: inline-block;
}
 
.testimonial17-text82 {
  display: inline-block;
}
 
.testimonial17-text83 {
  display: inline-block;
}
 
.testimonial17-text84 {
  display: inline-block;
}
 
.testimonial17-text85 {
  display: inline-block;
}
 
@media(max-width: 991px) {
  .testimonial17-container10 {
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
 
@media(max-width: 767px) {
  .testimonial17-container10 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .testimonial17-card10 {
    width: 100%;
  }
  .testimonial17-card11 {
    width: 100%;
  }
  .testimonial17-card12 {
    width: 100%;
  }
  .testimonial17-card13 {
    width: 100%;
  }
  .testimonial17-card14 {
    width: 100%;
  }
  .testimonial17-card15 {
    width: 100%;
  }
  .testimonial17-card16 {
    width: 100%;
  }
  .testimonial17-card17 {
    width: 100%;
  }
  .testimonial17-card18 {
    width: 100%;
  }
  .testimonial17-card19 {
    width: 100%;
  }
  .testimonial17-card20 {
    width: 100%;
  }
  .testimonial17-card21 {
    width: 100%;
  }
}
