.gallery141-gallery3 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.gallery141-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.gallery141-section-title {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.gallery141-text1 {
  text-align: center;
}
 
.gallery141-content {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
 
.gallery141-container1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.gallery141-image11 {
  width: 100%;
  height: 300px;
  max-width: 600px;
  align-self: center;
}
 
.gallery141-image12 {
  width: 100%;
  height: 300px;
  max-width: 600px;
  align-self: center;
}
 
.gallery141-image13 {
  width: 100%;
  height: 300px;
  max-width: 600px;
  align-self: center;
}
 
.gallery141-image14 {
  width: 100%;
  height: 300px;
  max-width: 600px;
  align-self: center;
}
 
.gallery141-container2 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.gallery141-image21 {
  width: 100%;
  height: 300px;
  max-width: 600px;
  align-self: center;
}
 
.gallery141-image22 {
  width: 100%;
  height: 300px;
  max-width: 600px;
  align-self: center;
}
 
.gallery141-image23 {
  width: 100%;
  height: 300px;
  max-width: 600px;
  align-self: center;
}
 
.gallery141-image24 {
  width: 100%;
  height: 300px;
  max-width: 600px;
  align-self: center;
}
 
.gallery141-container3 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.gallery141-image31 {
  width: 100%;
  height: 300px;
  max-width: 600px;
}
 
.gallery141-image32 {
  width: 100%;
  height: 300px;
  max-width: 600px;
}
 
.gallery141-image33 {
  width: 100%;
  height: 300px;
  max-width: 600px;
}
 
.gallery141-image34 {
  width: 100%;
  height: 300px;
  max-width: 600px;
}
 
.gallery141-text2 {
  display: inline-block;
}
 
@media(max-width: 991px) {
  .gallery141-content {
    align-items: center;
    flex-direction: column;
  }
  .gallery141-image11 {
    flex: 0 0 auto;
    width: 100%;
    height: 360px;
  }
  .gallery141-image12 {
    flex: 0 0 auto;
    width: 100%;
    height: 360px;
  }
  .gallery141-image13 {
    flex: 0 0 auto;
    width: 100%;
    height: 360px;
  }
  .gallery141-image14 {
    flex: 0 0 auto;
    width: 100%;
    height: 360px;
  }
  .gallery141-image21 {
    flex: 0 0 auto;
    width: 100%;
    height: 360px;
  }
  .gallery141-image22 {
    flex: 0 0 auto;
    width: 100%;
    height: 360px;
  }
  .gallery141-image23 {
    flex: 0 0 auto;
    width: 100%;
    height: 360px;
  }
  .gallery141-image24 {
    flex: 0 0 auto;
    width: 100%;
    height: 360px;
  }
  .gallery141-image31 {
    flex: 0 0 auto;
    width: 100%;
    height: 360px;
  }
  .gallery141-image32 {
    flex: 0 0 auto;
    width: 100%;
    height: 360px;
  }
  .gallery141-image33 {
    flex: 0 0 auto;
    width: 100%;
    height: 360px;
  }
  .gallery141-image34 {
    flex: 0 0 auto;
    width: 100%;
    height: 360px;
  }
}
